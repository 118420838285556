import React, { useEffect, useState, useMemo, useCallback, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import styles from './mdm-alerts.module.scss';
import classNames from 'classnames';
import { writeFile } from 'xlsx';
import * as XLSX from 'xlsx';
import {
    ArrowDownTrayIcon,
    ChevronDownIcon,
    ChevronUpDownIcon,
    ChevronUpIcon,
    GlobeAmericasIcon,
    XCircleIcon,
} from '@heroicons/react/24/outline';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    flexRender,
    ColumnResizeMode,
    ColumnResizeDirection,
    ColumnFiltersState,
    ColumnDef,
    getFilteredRowModel,
    FilterFn,
    SortingState,
    SortingFn,
    getSortedRowModel,
} from '@tanstack/react-table';
import { columnsAlerts } from './columns';
import {
    getRecipents,
    getCustomer,
    addRecipentToCustomer,
    deleteRecipent,
    getRecipentsBySettings,
    getRecipentsByCustomer,
    patchSetting,
    getTypeAlert,
    postSetting,
} from '../../services/python-services';
import Notiflix from 'notiflix';
import Multiselect from 'multiselect-react-dropdown';
import { stylesMultiSelectMDM } from '../../utils/constants';
import { MultiSelectDropdown } from '../multi-select-dropdown/multi-select-dropdown';

type GLPAlerts = {
    code: string;
    name: string;
    created_date: string;
    country: string;
    alert_by_customer_setting: Object[];
};

export const MdmAlert = () => {
    const [selectedOption, setSelectedOption] = useState('Choose');
    const columns = useMemo(() => columnsAlerts, []);
    const [recipientsData, setRecipients] = useState<string>('');
    const [customers, setCustomers] = useState<GLPAlerts[]>([]);
    const [recipentsByCustomer, setRecipentsByCustomer] = useState([]);
    const [newEmail, setNewEmail] = useState<{ [key: string]: string }>({});
    const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange');
    const [resEffect, setResEffect] = useState('');
    const [loader, setLoader] = useState(false);
    const [rowSelected, setRowSelected] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');
    const [countrySelect, setCountrySelect] = useState([]);
    const [isReset, setIsReset] = useState(false);
    const [customerQty, setCustomerQty] = useState(0);
    const [columnResizeDirection, setColumnResizeDirection] =
        useState<ColumnResizeDirection>('ltr');
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [alertType, setAlertTypes] = useState([]);

    const fetchData = async () => {
        try {
            const [response, customerResponse, typeAlerts] = await Promise.all([
                getRecipents(),
                getCustomer(),
                getTypeAlert(),
            ]);
            setRecipients(response);
            setCustomers(customerResponse);
            setAlertTypes(
                typeAlerts.map((x: any) => {
                    return x.id;
                })
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoader(false);
            setNewEmail({});
        }
    };

    useEffect(() => {
        fetchData();
    }, [resEffect]);

    useEffect(() => {
        const { getRowModel } = table;

        const { rows } = getRowModel();
        const dataSent = rows.map((x: any) => {
            return x.original;
        });
        const dataFiltered = dataSent.filter((x: any) => {
            const isNumeric = /^\d+$/.test(x.code);
            return isNumeric && x.code !== '0000' && x.code !== 'ALL' && x.code !== 'TBA';
        });

        setCustomerQty(dataFiltered.length);
    }, [customers, columnFilters, globalFilter]);

    const [showAllRecipients, setShowAllRecipients] = useState<{ [key: string]: boolean }>({});

    const toggleShowAll = useCallback((rowId: string) => {
        setShowAllRecipients((prev) => ({
            ...prev,
            [rowId]: !prev[rowId],
        }));
    }, []);

    const defaultData = useMemo(() => customers, [customers]);

    const customGlobalFilterFn: FilterFn<GLPAlerts> = (row, columnId, filterValue) => {
        console.log(columnId);
        return Object.entries(row.original).some(([key, value]) => {
            if (key === 'alert_by_customer_setting') {
                if (typeof value === 'string') {
                    return value.toLowerCase().includes(filterValue.toLowerCase());
                }
                if (Array.isArray(value)) {
                    return value.some((recipient: any) =>
                        recipient.recipent.email.toLowerCase().includes(filterValue.toLowerCase())
                    );
                }
                return false;
            }
            if (key === 'recipents') {
                return false;
            }
            if (typeof value === 'string') {
                return value.toLowerCase().includes(filterValue.toLowerCase());
            }
            if (typeof value === 'object' && value !== null) {
                return Object.values(value).some(
                    (val) =>
                        typeof val === 'string' &&
                        val.toLowerCase().includes(filterValue.toLowerCase())
                );
            }
            return false;
        });
    };
    const getCountries = async () => {
        const countrySet = new Set();

        customers.forEach((x: any) => {
            if (x.country && x.country.name) {
                countrySet.add(x.country.name);
            } else {
                countrySet.add('NO COUNTRY');
            }
        });

        const countrySelectData = Array.from(countrySet);
        //@ts-expect-error
        setCountrySelect(countrySelectData);
    };

    useEffect(() => {
        getCountries();
    }, [customers]);

    useEffect(() => {
        fetchData();
    }, [resEffect]);

    const table = useReactTable<GLPAlerts>({
        data: defaultData,
        columns,
        state: {
            globalFilter,
            columnFilters,
            sorting,
        },
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        globalFilterFn: customGlobalFilterFn,
        columnResizeMode,
        columnResizeDirection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    });

    const handleSelectChange = (event: any) => {
        setSelectedOption(event);
        setColumnFilters(() => {
            if (event.includes('All')) {
                return [];
            } else {
                return [{ id: 'country', value: event }];
            }
        });
    };

    const addRecipent = async (event: any, rowId: string, customerSelected: any) => {
        event.preventDefault();
        setLoader(true);
        setRowSelected(rowId);
        const rgxEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (rgxEmail.test(newEmail[rowId])) {
            const email = { email: newEmail[rowId] };
            try {
                const res = await addRecipentToCustomer(customerSelected, email);
                if (res.success) {
                    const r = await getRecipentsByCustomer(customerSelected);
                    r.forEach((x: any) => {
                        for (let t = 0; t < alertType.length; t++) {
                            if (x.email === email.email) {
                                const data: any = {
                                    customer: customerSelected,
                                    type: alertType[t],
                                    recipent: x.id,
                                };
                                postSetting(data);
                            }
                        }
                    });
                    setRecipentsByCustomer(r);
                    setResEffect(r);
                    Notiflix.Notify.success('Email added succesfully.');
                }
            } catch (error) {
                console.error('Error adding recipient:', error);
            }
        } else {
            Notiflix.Notify.failure('Type an email to add it to the client.');
            console.error('Invalid email');
            setLoader(false);
        }
    };

    const deleteRecipient = async (
        event: any,
        customerSelected: any,
        recipentsId: any,
        rowId: any
    ) => {
        event.preventDefault();
        setLoader(true);
        setRowSelected(rowId);

        try {
            for (let t = 0; t < alertType.length; t++) {
                const data: any = {
                    customer: customerSelected,
                    type: alertType[t],
                    recipent: recipentsId,
                };
                const res = await patchSetting(data);
                setResEffect(res);
            }
            Notiflix.Notify.success('Email deleted succesfuly');
        } catch (error) {
            console.error('Error deleting recipient:', error);
        }
    };

    const handleNewEmail = (event: any, rowId: string) => {
        const value = event.target.value;
        setNewEmail((prev) => ({ ...prev, [rowId]: value }));
    };

    const renderRecipients = (recipients: Object[], rowId: string) => {
        const limit = 2;
        const showAll = showAllRecipients[rowId];
        const uniqueRecipients = new Map();
        recipients.forEach((email) => {
            //@ts-expect-error
            uniqueRecipients.set(email.recipent.email, {
                //@ts-expect-error
                email: email.recipent.email,
                //@ts-expect-error
                id: email.recipent.id,
                //@ts-expect-error
                customerId: email.customer_id,
            });
        });
        return (
            <div className={styles.emailsAddedBox}>
                {uniqueRecipients.size > 0 && (
                    <>
                        {Array.from(uniqueRecipients.values())
                            .slice(0, showAll ? uniqueRecipients.size : limit)
                            .map((recipient, index) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}
                                    key={`${rowId}-${index}`}
                                >
                                    <div className={styles.emailsAdded}>
                                        {recipient.email}
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) =>
                                                deleteRecipient(
                                                    e,
                                                    recipient.customerId,
                                                    recipient.id,
                                                    rowId
                                                )
                                            }
                                        >
                                            <XCircleIcon className="h-5 w-5" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {showAll && (
                            <div
                                className={styles.seeMoreButton}
                                onClick={() => toggleShowAll(rowId)}
                            >
                                See less
                            </div>
                        )}
                    </>
                )}
                {uniqueRecipients.size > limit && !showAll && (
                    <div className={styles.seeMoreButton} onClick={() => toggleShowAll(rowId)}>
                        See {uniqueRecipients.size - limit} more
                    </div>
                )}
                {rowId === rowSelected && loader && (
                    <div className={styles.loader}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#282828"
                            className={`w-6 h-6 ${styles.spin}`}
                        >
                            <path d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" />
                        </svg>
                    </div>
                )}
            </div>
        );
    };

    const resetFilters = () => {
        setGlobalFilter('');
        setSelectedOption('Choose');
        setColumnFilters([]);
        setIsReset(true);
        setTimeout(() => {
            setIsReset(false);
        }, 0);
    };

    const exportExcel = () => {
        const formatDate = (dateString: any) => {
            if (!dateString) return 'null';

            const date = new Date(dateString);
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();

            return `${month}-${day}-${year}`;
        };

        const data = defaultData.map((row: any) => ({
            'Ship to code': row.code === null ? 'null' : row.code,
            'Ship to name': row.name === null ? 'null' : row.name,
            'Creation date': row.created_date === null ? 'null' : formatDate(row.created_date),
            'Creation country': row.country === null ? 'null' : row.country.name,
            Recipients:
                row.alert_by_customer_setting === null
                    ? 'null'
                    : row.alert_by_customer_setting
                          .map((recipient: any) => recipient.recipent.email)
                          .join(', '),
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'GLP Alerts');

        XLSX.writeFile(workbook, 'MDM_Alerts.xlsx');
    };

    /*     const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            console.log(e);
            addRecipent(e, row.id, cell.row.original.id);
        }
    };
 */
    return (
        <div className={classNames(styles.root)}>
            <div className={styles.topWrapper}>
                <div className={styles.tittleContainer}>GLP Ship-to Recipients Management</div>
            </div>
            <div className={styles.buttonsTop}>
                <div className={styles.buttonsLeft}>
                    <div className={styles.filter}>
                        <div className={styles.firstNameInput}>
                            <label htmlFor="country">Filter by country</label>
                            {/* <GlobeAmericasIcon className="w-5 h-5 text-gray-500" />
                            <select
                                className={styles.dropDown}
                                name="Choose"
                                id="country"
                                value={selectedOption.toString()}
                                onChange={handleSelectChange}
                            >
                                <option key={'companyNull'} value={'ALL'}>
                                    ALL
                                </option>
                                {countrySelect.map((x) => (
                                    <option value={x}>{x}</option>
                                ))}
                            </select> */}
                            <MultiSelectDropdown
                                icon={<GlobeAmericasIcon className="w-5 h-5 text-gray-500" />}
                                options={countrySelect}
                                chooseLegend={'Choose country'}
                                onChange={handleSelectChange}
                                reset={isReset}
                            />
                        </div>
                    </div>
                    <div className={styles.filter} onClick={resetFilters}>
                        <div className={styles.firstNameInput}>
                            <div>
                                <div className={styles.customButton}>
                                    <div className={styles.button} id="reset">
                                        Reset
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonsRight}>
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder="Search"
                        style={{
                            height: '34px',
                            borderRadius: '5px',
                            border: '1px solid #C4C4C4',
                            paddingRight: '26px',
                            width: '250px',
                        }}
                    />
                    <div className={styles.filter}>
                        <div className={styles.firstNameInput}>
                            <div>
                                <div className={styles.customButton}>
                                    <div className={styles.button} id="reset" onClick={exportExcel}>
                                        <ArrowDownTrayIcon className="h-4 w-4" /> Export
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ margin: '5px 35px', overflow: 'scroll', height: '735px' }}>
                <div style={{ direction: table.options.columnResizeDirection }}>
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            {...{
                                                key: header.id,
                                                colSpan: header.colSpan,
                                                style: {
                                                    width: header.getSize(),
                                                    position: 'sticky',
                                                    borderRight:
                                                        header.id === 'recipients'
                                                            ? '2px solid #C4C4C4'
                                                            : undefined,
                                                    borderLeft:
                                                        header.id === 'recipients'
                                                            ? '2px solid #C4C4C4'
                                                            : undefined,
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '5px',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div
                                                        {...{
                                                            className: header.column.getCanSort()
                                                                ? 'cursor-pointer select-none'
                                                                : '',
                                                            onClick:
                                                                header.column.getToggleSortingHandler(),
                                                            style: {
                                                                ...{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '5px',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                        </div>

                                                        {{
                                                            asc: (
                                                                <ChevronDownIcon className="w-4 h-4" />
                                                            ),
                                                            desc: (
                                                                <ChevronUpIcon className="w-4 h-4" />
                                                            ),
                                                        }[
                                                            header.column.getIsSorted() as string
                                                        ] ?? (
                                                            <ChevronUpDownIcon className="w-6 h-6" />
                                                        )}
                                                    </div>
                                                )}
                                                <div
                                                    {...{
                                                        onDoubleClick: () =>
                                                            header.column.resetSize(),
                                                        onMouseDown: header.getResizeHandler(),
                                                        onTouchStart: header.getResizeHandler(),
                                                        className: `${styles.resizer} ${
                                                            table.options.columnResizeDirection
                                                        } ${
                                                            header.column.getIsResizing()
                                                                ? 'isResizing'
                                                                : ''
                                                        } ${header.column.getCanSort()}`,
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                />
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            {...{
                                                key: cell.id,
                                                style: {
                                                    width:
                                                        cell.column.id === 'addRecipients'
                                                            ? '20px'
                                                            : cell.column.getSize(),
                                                    borderLeft:
                                                        cell.column.id ===
                                                        'alert_by_customer_setting'
                                                            ? '1.5px solid #C4C4C4'
                                                            : undefined,
                                                    borderRight:
                                                        cell.column.id ===
                                                        'alert_by_customer_setting'
                                                            ? '1.5px solid #C4C4C4'
                                                            : undefined,
                                                },
                                                className:
                                                    cell.column.id !== 'alert_by_customer_setting'
                                                        ? styles.tableCell
                                                        : styles.recipients,
                                            }}
                                        >
                                            {cell.column.id === 'alert_by_customer_setting'
                                                ? renderRecipients(
                                                      cell.getValue<
                                                          GLPAlerts['alert_by_customer_setting']
                                                      >(),
                                                      row.id
                                                  )
                                                : flexRender(
                                                      cell.column.columnDef.cell,
                                                      cell.getContext()
                                                  )}
                                            {cell.column.id === 'addRecipients' && (
                                                <div className={styles.addRecipents}>
                                                    <form>
                                                        <div className={styles.insideAddRecipients}>
                                                            <input
                                                                value={newEmail[row.id] || ''}
                                                                type="email"
                                                                onChange={(e) =>
                                                                    handleNewEmail(e, row.id)
                                                                }
                                                                placeholder="Type new recipient"
                                                                className={styles.input}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        addRecipent(
                                                                            e,
                                                                            row.id,
                                                                            //@ts-expect-error
                                                                            cell.row.original.id
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                fill="#282828"
                                                                className="size-7"
                                                                onClick={(e) =>
                                                                    addRecipent(
                                                                        e,
                                                                        row.id,
                                                                        //@ts-expect-error
                                                                        cell.row.original.id
                                                                    )
                                                                }
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </form>
                                                </div>
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className={styles.tdFoot}></td>
                                <td colSpan={1000} className={styles.tdFoot}>
                                    Unique Ship to Values: {customerQty}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
};

function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    style,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
    style?: any;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <input {...props} value={value} onChange={(e) => setValue(e.target.value)} style={style} />
    );
}
