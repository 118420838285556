import styles from './tree-view-nested-row.module.scss';
import classNames from 'classnames';
import { TreeViewNestedRowChildren } from '../tree-view-nested-row-children/tree-view-nested-row-children';
import { CustomCheckbox } from '../custom-checkbox/custom-checkbox';
import { useState, useEffect } from 'react';


export interface TreeViewNestedRowProps {
    className?: string;
    row: any;
    setParentClicked: any;
    columnWidth: any;
    parentValue: any;
    setParentValue: any;
    parentClicked: any;
    initialDepth: number;
    depthCounter: number;
    addIdsArray: any;
    arrayOfPreSelected: any;

}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TreeViewNestedRow = ({
    className,
    parentValue,
    row,
    setParentValue,
    setParentClicked,
    parentClicked,
    columnWidth,
    initialDepth,
    depthCounter, 
    addIdsArray,
    arrayOfPreSelected

}: TreeViewNestedRowProps) => {

    const [currentNodeValue, setCurrentNodeValue] = useState(false)
    const [currentNodeClicked, setCurrentNodeClicked] = useState(false)




    
    useEffect(() => {
        addIdsArray(row["id"], currentNodeValue)
    }, [currentNodeValue]);

    useEffect(() => {
        
        if (parentClicked) {

            setCurrentNodeValue(parentValue)
            setCurrentNodeClicked(parentClicked)
        }
        
    }, [parentClicked, parentValue]);

    useEffect(() => {
        if (!parentClicked && currentNodeClicked) {
            setParentValue(true)
            setParentClicked(false)
        }
    }, [currentNodeClicked]);

    useEffect(() => {
        setParentClicked(false)
        if (currentNodeValue) setParentValue(currentNodeValue)
        // setaccess*(node id,currentNodeValue)
    }, [currentNodeValue]);


    useEffect(() => {
        let a = [...arrayOfPreSelected]
        console.log("a", a)
        let holder = a.filter((x:number) => x === row["id"]);
        console.log("rowID", row["id"])
        console.log(holder, "holder")
        if (holder.length > 0) {
            setCurrentNodeValue(true);
        }
    }, []);
    


    function handleClick() {
        setCurrentNodeClicked(true)
        setCurrentNodeValue(x => !x)
    }
    
    let dynamicStyle
    if (row["children"].length===0 && initialDepth!==depthCounter) {
        dynamicStyle =  { width: (initialDepth+1-depthCounter)*(100/initialDepth)+"vw", display: "flex", flexGrow: 1 }
    }
    else {
        dynamicStyle =  { width: columnWidth, display: "flex" }
    }

    return (
        <div>
            <div className={classNames(styles.root, className)}>
                <div style={dynamicStyle} className={classNames(styles.borders)}>
                    <CustomCheckbox ifChecked={currentNodeValue} handleClick={handleClick} />
                    <div className={classNames(styles.fontColor)}>{row.description}</div>
                </div>
                <div className={classNames(styles.verticalRows, styles.secondChild)}>
                    <TreeViewNestedRowChildren
                        addIdsArray={addIdsArray}
                        parentValue={currentNodeValue}
                        setParentValue={setCurrentNodeValue}
                        columnWidth={columnWidth} 
                        rowChildren={row.children} 
                        parentClicked={currentNodeClicked} 
                        setParentClicked={setCurrentNodeClicked} 
                        initialDepth={initialDepth}
                        depthCounter={depthCounter}
                        arrayOfPreSelected={arrayOfPreSelected}
                        />
                </div>
            </div>
        </div>

    )
};

/* IF !ROW.CHILDREN THEN DONT PRODUCE TREEVIEWNESTEDROWCHILDREN INSTEAD CREATE AN EMPTY COMPONENT THAT FILLS IN THE BOX until N depth which you pass as a prop */