import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';
import { clearLocalStorage, getEnvironment, returnToLogin } from '../utils/functions';
import Notiflix from 'notiflix';
import { setInterceptorError } from '../utils/functions';

const URL = window.location.href;
const validatUrl = (url: string) => {
    return (
        (url?.includes('token') && !url?.includes('gitbook')) ||
        url?.includes('administration/authOptions') ||
        url?.includes('login') ||
        url?.includes('otp') ||
        url?.includes('passwordlessEmail/link') ||
        url?.includes('/changePasswordOut') ||
        url?.includes('/changePasswordEmail')
    );
};

function getEnv() {
    if (getEnvironment(URL) === 'prod' ){
        return `https://lrm-api-prod.cheilbidx.io`;
    } 
    else if (getEnvironment(URL) === 'staging' ){
        return `https://lrm-api-staging.cheilbidx.io`;
    } 
    else if (getEnvironment(URL) === 'qa' ){
        return `https://lrm-api-qa.cheilbidx.io`;
    } 
    else if (getEnvironment(URL) === 'dev' || window.location.host.indexOf('localhost') > -1) {
        // return `http://127.0.0.1:8000/`;
        return `https://lrm-api-dev.cheilbidx.io`;
    }
    else {
        return `https://lrm-api-dev.cheilbidx.io`;
    }
}

const url = getEnv();
// console.log('url', url);
export const axiosInstance = axios.create({
    baseURL: url,
});

axiosInstance.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    const isLogin = validatUrl(request?.url || '');
    if (!isLogin && localStorage.getItem('tokenkey') !== null) {
        request.headers['Authorization'] = `Bearer ${localStorage.getItem('tokenkey')}`;
    }
    request.headers['Content-Type'] = 'application/json';
    request.paramsSerializer = (params) =>
        qs.stringify(params, {
            skipNulls: true,
            encoder: (str) => {
                return encodeURI(str);
            },
        });
    return request;
});

axiosInstance.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    (err: any) => {
        //console.log("error in interaction with token", err);
        if (err?.message === 'Network Error') {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            console.log(err.message);
            // window.location.replace('/login');
            clearLocalStorage();
            return Notiflix.Notify.failure(err.message);
        }else if((err?.response?.status === 401) 
            // && (err?.response?.data?.detail ==="Authentication credentials were not provided." 
            // ||err?.response?.data?.detail.includes("please log in"))
        ){
            window.location.replace('/login');
            clearLocalStorage();
            return Notiflix.Notify.failure(err.response.data.detail);
        }
        else if (err?.response?.status === 401) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            console.log(err.response?.data);
            return Notiflix.Notify.failure(err.response.data.detail);
        }else  if (err?.response?.status === 503) {
            // 503 status codes refers to a invalid server
            console.log(err?.response?.data);
            return Notiflix.Notify.failure('The server is currently unavailable. Please try again later.');
        } else {
            //Notiflix.Notify.failure('Error with the request');
            setInterceptorError(err);
            console.error(err);
            return err;
        }
    }
);

// export const PrivateInterceptor = () => {
//     console.log("INTERCEPTOR",localStorage.getItem('tokenkey'))
//     if (localStorage.getItem('tokenkey')) {

//         axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
//             const isLogin = validatUrl(request?.url || "");
//             if (!isLogin) {
//                 request.headers['Authorization'] = `Bearer ${localStorage.getItem("tokenkey")}`;
//             }
//             request.headers['Content-Type'] = 'application/json';
//             request.paramsSerializer = (params) =>
//                 qs.stringify(params, {
//                     skipNulls: true,
//                     encoder: (str) => {
//                         return encodeURI(str);
//                     },
//                 });
//             return request;
//         });

//         axios.interceptors.response.use(
//             (response: AxiosResponse) => {
//                 // Any status code that lie within the range of 2xx cause this function to trigger
//                 return response;
//             },
//             (err: AxiosError) => {
//                 console.log("error in interaction with token", err);
//                 if (err.response?.status === 401)
//                     // Any status codes that falls outside the range of 2xx cause this function to trigger
//                     return returnToLogin(null, false, false, false);
//                 else {
//                     Notiflix.Notify.failure("Error with the request: " + err?.toString());
//                     return err;
//                 }
//             }
//         );
//     }
//     else {
//         axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
//             const isLogin = validatUrl(request?.url || "");
//             if (!isLogin) {
//                 request.headers['Authorization'] = `Bearer ${localStorage.getItem("tokenkey")}`;
//             }
//             request.headers['Content-Type'] = 'application/json';
//             request.paramsSerializer = (params) =>
//                 qs.stringify(params, {
//                     skipNulls: true,
//                     encoder: (str) => {
//                         return encodeURI(str);
//                     },
//                 });
//             return request;
//         });
//         axios.interceptors.response.use(
//             (response: AxiosResponse) => {
//                 // Any status code that lie within the range of 2xx cause this function to trigger
//                 return response;
//             },
//             (err: AxiosError) => {
//                 console.log("error in interaction no token", err);
//                 // Any status codes that falls outside the range of 2xx cause this function to trigger
//                 if (err.response?.status === 401)
//                     return returnToLogin(null, false, false, false);
//                     else {
//                         Notiflix.Notify.failure("Error with the request: " + err?.toString());
//                         return err;
//                     }
//             }
//         );
//     }
// };
