import { Route, Routes, BrowserRouter, Navigate, useParams } from 'react-router-dom';
//import { PrivateInterceptor } from './interceptors/private.interceptor';
import { MasterFile } from './components/master-file/master-file';
import { PivotBase } from './components/pivot-base/pivot-base';
import { GlpReport } from './components/glp-report/glp-report';
import { Rmf } from './components/rmf/rmf';
import { RolesTable } from './components/roles-table/roles-table';
import { EditRole } from './components/edit-role/edit-role';
import { Roles } from './components/roles/roles';
import { Members } from './components/members/members';
import { Executive } from './components/executive/executive';
import { GlpLogisticReport } from './components/glp-logistic-report/glp-logistic-report';
import { GlpMonitoringReport } from './components/glp-monitoring-report/glp-monitoring-report';
import { GlpKeyMapping } from './components/glp-key-mapping/glp-key-mapping';
import { GlpAlerts } from './components/glp-alerts/glp-alerts';
import { TreeBase } from './components/tree-base/tree-base';
import { LoginRegularBase } from './components/login-regular-base/login-regular-base';
import { RMContext } from './context/RMContext';
import { useContext, useEffect, useState } from 'react';
import { PasswordLessComponent } from './components/passwordless/passwordless-component';
import { LoginForgotPasswordBase } from './components/login-forgot-password-base/login-forgot-password-base';
import {
    generateFirstNodePath,
    getDataToFillContext,
    getQueryParams,
    updateIsLocalToContext,
} from './utils/functions';
import { Home } from './components/home/home';
import { UsersManagement } from './components/users-management/users-management';
import { GlpPorts } from './components/glp-ports/glp-ports';
import { GalleryReports } from './components/gallery-reports/gallery-reports';
import { PbiEmbed } from './components/pbi-embed/pbi-embed';
import styles from './App.module.scss';
import { GlpAlertsEmbeded } from './components/glp-alerts-embeded/glp-alerts-embeded';
import { GlpAlertView } from './components/glp-alert-view/glp-alert-view';
import SideBar from './components/sidebar-menu/sidebar-menu';
import { HeaderBar } from './components/header-bar/header-bar';
import { SecurityPolicies } from './components/security-policies/security-policies';
import { use } from 'echarts';
import { MdmAlert } from './components/mdm-alerts/mdm-alert';
import { SsoLogin } from './components/sso-login/sso-login';
import { UsersManagementTest } from './components/users-management-test/users-management-test';

function App() {
    // const [userData, setUserData] = useState(initialContextState);
    // const { setUserData, userData } = useContext(RMContext);
    const { setUserData, userData } = useContext(RMContext);
    const queries: any = getQueryParams(window.location.search);
    const [loaded, setLoaded] = useState(false);
    const [showNavBar, setShowNavBar] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const [firstPath, setFirstPath] = useState(localStorage.getItem('firstpath') || '');
    const login = localStorage.getItem('login');
    const [isLogin, setIsLogin] = useState(login || '');
    const logingInV = localStorage.getItem('logingIn');
    const [logingIn, setLogingIn] = useState(logingInV || '');
    const urlParams = new URLSearchParams(window.location.search);

    const URL = window.location.href;
    const currentPath = window.location.pathname;

    useEffect(() => {
        if (
            currentPath.includes('login') ||
            currentPath.includes('passwordless') ||
            currentPath.includes('forgot-password') ||
            currentPath.includes('sso-login') ||
            URL.includes('login') ||
            URL.includes('passwordless') ||
            URL.includes('forgot-password') ||
            URL.includes('sso-login') ||
            isLogin !== 'true' ||
            logingIn !== 'false'
        ) {
            setShowSideBar(false);
            setShowNavBar(false);
        } else {
            setShowSideBar(true);
            setShowNavBar(true);
        }
    }, [currentPath, window.location]);

    useEffect(() => {}, [showSideBar, showNavBar]);

    useEffect(() => {
        //set up the environment
        if (queries.e) {
            localStorage.setItem('env', queries.e);
        }
        if (
            !window.location.href.includes('passwordless') &&
            !window.location.href.includes('login') &&
            !window.location.href.includes('sso-login') &&
            !userData.hasOwnProperty('isLocal') &&
            !loaded
        ) {
            // Update the context using userDataContext, not local state userData
            updateIsLocalToContext(setUserData, userData, false);
            if (queries.t) setLoaded(false);
            else setLoaded(true);
            //setShowNavBar(false);
        } else if (
            !loaded &&
            !window.location.href.includes('login') &&
            !window.location.href.includes('passwordless') &&
            !window.location.href.includes('sso-login') &&
            queries.t === undefined
        ) {
            updateIsLocalToContext(setUserData, userData, true);
            setLoaded(true);
            //setShowNavBar(true);
        } else {
            setLoaded(true);
            //setShowNavBar(true);
        }
        if (queries.t !== undefined) {
            localStorage.setItem('tokenkey', queries.t);
            updateIsLocalToContext(setUserData, userData, false);
        } else if (
            !window.location.href.includes('/passwordless') &&
            !window.location.href.includes('sso-login') &&
            !window.location.href.includes('/login') &&
            !window.location.href.includes('forgot-password') &&
            isLogin === 'true'
        ) {
            setShowNavBar(true);
            updateIsLocalToContext(setUserData, userData, true);
        } else {
            setShowNavBar(false);
        }
        // if (queries.t === undefined && !window.location.href.includes("/passwordless") && !window.location.href.includes("/login")){
        //     setShowNavBar(true);
        // }
    }, [queries.t]);
    useEffect(() => {
        const fetchData = async () => {
            // show navbar only and only if I am NOT using a token or I'm in passwordless or in the login
            const logginIn = localStorage.getItem('loggingIn') || '';
            const token =
                window.location.href.includes('/passwordless') || queries.t
                    ? queries.t
                    : localStorage.getItem('tokenkey');

            if (
                loaded &&
                userData.hasOwnProperty('sitemap') &&
                userData.sitemap.length === 0 &&
                token !== null &&
                ((logginIn !== 'true' && logginIn !== '') ||
                    (!window.location.href.includes('passwordless') &&
                        !window.location.href.includes('sso-login')))
            ) {
                await getDataToFillContext(setUserData, userData);
            }
        };

        fetchData(); // Call the async function immediately
    }, [loaded]);

    const { sitemap } = userData;
    useEffect(() => {
        if (sitemap.length > 0) {
            const firstPath = generateFirstNodePath(sitemap[0]);
            setFirstPath(firstPath);
            localStorage.setItem('firstpath', firstPath);
            setIsLogin(localStorage.getItem('login') || '');
            setLogingIn('false');
        }
    }, [sitemap, firstPath]);

    useEffect(() => {
        if (logingIn === 'false') {
            setShowNavBar(true);
            setShowSideBar(true);
        }
    }, [logingIn]);

    //get dynamicly routes to glp_dashboard
    const DynamicRoute = () => {
        const { child, reportType } = useParams();

        if (reportType?.includes('mtr')) {
            return <GlpMonitoringReport name="Report Monitoring" />;
        } else if (reportType?.includes('log')) {
            return <GlpLogisticReport name="Report Logistic" />;
        } else if (reportType?.includes('sl')) {
            return <GlpReport name="Report Sales" />;
        } else {
            return <div>Report not found</div>;
        }
    };
    //PrivateInterceptor();
    return (
        // <RMContext.Provider value={{ userData, setUserData }}>
        loaded ? (
            <>
                <BrowserRouter>
                    {showSideBar && showNavBar && <SideBar />}
                    {showNavBar && <HeaderBar />}
                    <div
                        className={`${styles['main-page-wrapper']}  ${
                            showSideBar && showNavBar
                                ? styles['pageWithSideBar']
                                : styles['pageWithOutSideBar']
                        }`}
                    >
                        <Routes>
                            <Route path="/settings/roles_management" element={<Roles />}>
                                <Route path="" element={<RolesTable />} />
                                <Route
                                    path=":id/countries/"
                                    element={<EditRole selector="countries" />}
                                />
                                <Route path=":id/roles-tree-view/" element={<TreeBase />} />
                                <Route
                                    path=":id/divisions/"
                                    element={<EditRole selector="divisions" />}
                                />
                                <Route path=":id/members/" element={<Members />} />
                            </Route>
                            {/* <Route path="/" element={<Home />} /> */}
                            {firstPath && <Route path="/" element={<Navigate to={firstPath} />} />}
                            {isLogin === 'true' ? (
                                <Route path="" element={<Navigate to={firstPath} />} />
                            ) : (
                                <Route path="" element={<Navigate to={'/login'} />} />
                            )}
                            <Route
                                path=""
                                element={
                                    <Navigate
                                        to={
                                            firstPath === '' && sitemap.length > 0
                                                ? '/login'
                                                : firstPath
                                        }
                                    />
                                }
                            />
                            <Route path="/home" element={<Home />} />
                            <Route path="/login" element={<LoginRegularBase />} />
                            <Route path="/forgot-password" element={<LoginForgotPasswordBase />} />
                            <Route path="/passwordless/" element={<PasswordLessComponent />} />
                            <Route path="/sso-login" element={<SsoLogin />} />
                            <Route path="/pivot-table" element={<PivotBase />} />
                            <Route
                                path="/coming_soon/master-file/:file/"
                                element={<MasterFile />}
                            />

                            <Route path="/pbiembed" element={<PbiEmbed />} />
                            <Route
                                path="/glp_dashboard/glp_dashboard_:child/glp_dashboard_:reportType"
                                element={<DynamicRoute />}
                            />
                            <Route
                                path="/glp_v2/glp_sl"
                                element={<GlpReport name="Report Sales" />}
                            />
                            <Route
                                path={'/glp_v2/glp_log'}
                                element={<GlpLogisticReport name="Report Logistic" />}
                            />
                            <Route
                                path="/glp_v2/glp_mtr"
                                element={<GlpMonitoringReport name="Report Monitoring" />}
                            />
                            <Route
                                path="/glp_v2/key-mapping"
                                element={<GlpKeyMapping name="Report Key Mapping" />}
                            />
                            <Route
                                path="/glp_v2/glp_ale"
                                element={<GlpAlerts name="Report Alerts" />}
                            />
                            <Route path="/glp_v2/glp_map" element={<GlpPorts name="Glp Ports" />} />
                            <Route
                                path="/glp_v2/glp_aleview"
                                element={<GlpAlertsEmbeded name="Alerts Visibility" />}
                            />
                            <Route path="/glp_v2/glp_alert_visibility" element={<GlpAlertView />} />
                            <Route path="/rmf" element={<Rmf />} />
                            <Route path="/executive-local" element={<Executive />} />
                            <Route path={'/settings/admin_profile'} element={<UsersManagement />} />
                            <Route
                                path={'/policies'}
                                element={
                                    <SecurityPolicies
                                        setUserData={setUserData}
                                        userData={userData}
                                    />
                                }
                            />
                            <Route
                                path={'/settings/users_management'}
                                element={<UsersManagement />}
                            />
                            <Route
                                path={'/users_management_test'}
                                element={<UsersManagementTest />}
                            />
                            <Route
                                path={'/mdm/mdm_glp_ship_to_recipients'}
                                element={<MdmAlert />}
                            />
                            <Route path="/:parent" element={<GalleryReports />} />
                            <Route path="/:parent/:child" element={<GalleryReports />} />
                            <Route path="/glp_v2/:parent" element={<GalleryReports />} />
                            {/* <Route path="/glp_v2/:parent/:child" element={<GalleryReports />} /> */}
                            <Route path="/:parent/:child/:view" element={<PbiEmbed />} />
                            {/* <Route path="/settings/data_control" element={<PbiEmbed />} /> */}
                        </Routes>
                    </div>
                </BrowserRouter>
            </>
        ) : (
            <></>
        )
        // </RMContext.Provider>
    );
}

export default App;
