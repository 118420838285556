/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { FilterButton } from '../filter-button/filter-button';
import { FilterCard } from '../filter-card/filter-card';
//import { Table } from '../table/table';
import { Table } from '@cheilca/cheil-commons';
import { Loader } from '../loader/loader';
import { MasterFileDataComparison } from '../master-file-data-comparison/master-file-data-comparison';
import Pos_module from './pos/pos.module.scss';
import { getPythonData, getMasterFile, getUserMe } from '../../services/python-services';
import { Column, PointOfSale } from '../../utils/interfaces';

import { useParams } from 'react-router-dom';
import { getColunmsByFile } from '../../pipes/columns/get-columns';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import styles from './master-file.module.scss';

export interface PosProps {
    className?: string;
    initialData?: object[];
}
export interface MasterFileProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const MasterFile = ({ initialData }: PosProps) => {
    const { file } = useParams();
    const title =
        file === 'pos'
            ? 'Master File: Point of Sales'
            : file === 'weeklysales'
            ? 'Master File: Weekly Sales'
            : file === 'products'
            ? 'Products'
            : file === 'market_share'
            ? 'Market Share'
            : 'Master File: Pre-Weekly Sales';
    const filtersString =
        file === 'products'
            ? ['division', 'product-group', 'segments']
            : file === 'market_share'
            ? ['country', 'site_id', 'product-division', 'year', 'week']
            : [
                  'region',
                  'countryZone',
                  'store',
                  'country',
                  'photo',
                  'is_rmf',
                  'universe',
                  'status',
                  'site_id',
              ];
    const [data, setData] = useState(initialData ? initialData : []);
    const [closedFilters, setClosedFilters] = useState(false);
    const [closedComparison, setClosedComparison] = useState(true);
    const [moreItems, setMoreItems] = useState(true);

    const [selectedPOS, setSelectedPOS] = useState(0);
    const [selectedPOSData, setSelectedPOSData] = useState({} as PointOfSale);
    const [posDataLoaded, setPosDataLoaded] = useState(false);

    const [page, setPage] = useState(1);

    const [totalItems, setTotalItems] = useState(0);
    const [loader, setLoader] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    const [columns] = useState<Column[]>(getColunmsByFile(file, setSelectedPOS));

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingDataInit, setIsLoadingDataInit] = useState(false);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [isInitCallDone, setIsInitCallDone] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [queries, setQueries] = useState<PointOfSale>({
        page: 1,
        size: 50,
        point_of_sale_country: null,
        point_of_sale_region: null,
        point_of_sale_zone: null,
        point_of_sale_account: null,
        kiosco: null,
        photo: null,
        search: null,
        sort_by: null,
        is_rmf: file === 'pos' ? '1' : null,
        universe: file === 'pos' ? '1' : null,
        status: file === 'pos' ? '1' : null,
        'country__name:not': file === 'pos' ? 'PARAGUAY' : null,
    });

    const [customFilters, setCustomFilters] = useState<any>(
        file === 'pos'
            ? [[1, 'country__name', ':not', 'PARAGUAY', 'no_equal', 'value', undefined]]
            : []
    );

    const [uniques, setUniques] = useState<PointOfSale>({
        page: 1,
        size: 50,
        point_of_sale_country: null,
        point_of_sale_region: null,
        point_of_sale_zone: null,
        point_of_sale_account: null,
        kiosco: null,
        photo: null,
        search: null,
        sort_by: null,
        is_rmf: file === 'pos' ? '1' : null,
        universe: file === 'pos' ? '1' : null,
        status: file === 'pos' ? '1' : null,
        'country__name:not': file === 'pos' ? 'PARAGUAY' : null,
    });
    const anyFilter = () => {
        let isFiltering = false;
        Object.entries(queries).forEach(([key, value]) => {
            if (key !== 'size' && key !== 'page' && key !== 'sort_by' && key !== 'search') {
                if (value !== null) {
                    isFiltering = true;
                    return;
                }
            }
        });
        return isFiltering;
    };

    const fetchData = async (isScroll: boolean = false) => {
        // Evitar llamadas adicionales si ya hay una solicitud en curso
        if (isLoadingData) {
            return;
        }
        const updatedQueries = { ...queries, page: page, size: 50 };
        console.log('fetchData');
        setQueries(updatedQueries);
        setIsLoadingDataScroll(isScroll);
        setIsLoadingData(true); // Establecer isLoadingData a true antes de la solicitud

        try {
            // Realizar la solicitud
            const res: any = await getPythonData(file ?? '', updatedQueries);

            // Actualizar los datos y las variables de estado
            const newData = [...data, ...res.items];
            setData(newData);
            setIsLoadingData(false);

            setTotalItems(res.total);
            if (res.pages === page) {
                setMoreItems(false);
            } else {
                setPage((prev) => prev + 1);
            }
            setIsInitCallDone(false);
            setIsLoadingDataScroll(false);
            setIsLoadingDataInit(true);
            // Restablecer isLoadingData a false después de la solicitud
        } catch (error) {
            setIsLoadingDataScroll(false);
            setIsLoadingData(false);
            // Restablecer isLoadingData a false en caso de error
        }
    };

    const clearValues = () => {
        setData([]);
        setTotalItems(0);
        setPage(1);
    };

    async function getUser() {
        const user_: any = await getUserMe();
        setUserEmail(user_.email);
    }

    useEffect(() => {
        if (!isInitCallDone) {
            setIsInitCallDone(true);
            fetchData();
        }
    }, [queries]);

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (selectedPOS === 0) return;
        (async () => {
            setPosDataLoaded(false);
            const data = await getMasterFile(file ?? '', 'point_of_sale', selectedPOS);
            setSelectedPOSData(data[0]);
            setPosDataLoaded(true);
        })();
        setClosedComparison(false);
    }, [selectedPOS]);

    const closeFilter = (isClosed: boolean) => {
        setClosedFilters(isClosed);
    };

    const applyFilters = (filters: any, type: string = '') => {
        if (type === 'reset') {
            resetFilters();
        } else {
            clearValues();
            const updatedQueries = { ...filters, page: 0 };
            setQueries(updatedQueries);
            setUniques(updatedQueries);
        }
    };

    const resetFilters = () => {
        clearValues();
        const updatedQueries = {
            point_of_sale_country: null,
            point_of_sale_region: null,
            point_of_sale_zone: null,
            point_of_sale_account: null,
            kiosk: null,
            photos: null,
            page: 0,
            search: null,
            sort_by: null,
        };
        setQueries(updatedQueries);
    };

    const updateData = (newData: any) => {
        setData([]);
        setData(newData);
    };

    const searchData = (type: any) => {
        setSearchText(type);
        if (type === '') {
            clearValues();
            setQueries({ ...queries, search: null });
        } else if (type.length > 2) {
            clearValues();
            setQueries({ ...queries, search: type.toUpperCase() });
        }
    };

    const sortData = (type: any) => {
        if (type.name === 'Descending') {
            clearValues();
            setQueries({ ...queries, sort_by: `${type.prop},desc` });
        } else {
            clearValues();
            setQueries({ ...queries, sort_by: `${type.prop}` });
        }
    };

    const exportData = async (c: any) => {
        if (anyFilter() || file === 'pos') {
            setLoader(true);
            const column_s = c.filter((i: any) => i.show).map((i: any) => `${i.prop}:${i.name}`);
            const queryExport = { ...queries, export: true, columns: column_s.join(',') };
            const res: any = await getPythonData(file ?? '', queryExport);
            window.open(res.url, '_blank');
            setLoader(false);
        } else {
            const dialog = document.getElementById('dialog-download');
            //@ts-expect-error
            if (dialog) dialog.showModal();
        }
    };

    function closeDialog(): void {
        const dialog = document.getElementById('dialog-download');
        //@ts-expect-error
        if (dialog) dialog.close();
    }

    return (
        <div className="flex h-screen justify-between gap-2 px-4 pb-6 pt-2">
            <dialog id="dialog-download" className={classNames(styles.dialogDownload)}>
                <div className="flex flex-col justify-center items-center">
                    <ExclamationTriangleIcon />
                    To export data, please select at least one filter.
                    <button className="btn" onClick={closeDialog}>
                        Ok
                    </button>
                </div>
            </dialog>
            {closedFilters ? <FilterButton className="hidden" close={closeFilter} /> : <></>}
            <FilterCard
                style={
                    closedFilters
                        ? {
                              width: '0px',
                              'min-width': '0px',
                              'max-width': '0px',
                              padding: '0px',
                          }
                        : {}
                }
                close={closeFilter}
                applyFilters={applyFilters}
                columns={columns}
                setUniques={setUniques}
                filters={filtersString}
                file={file ? file : ''}
                uniques={uniques}
                onReset={clearValues}
                customFilters={customFilters}
                setCustomFilters={setCustomFilters}
            />
            {isLoadingDataInit ? (
                <div
                    className={
                        !closedFilters && !closedComparison
                            ? ' w-[55%] '
                            : !closedFilters && closedComparison
                            ? ' w-[80%] '
                            : closedFilters && !closedComparison
                            ? ' w-[75%] '
                            : ' w-full '
                    }
                >
                    <Table
                        title={title}
                        haveMoreItems={moreItems}
                        data={data}
                        columns={columns}
                        fetchData={fetchData}
                        updateData={updateData}
                        toolsNative={false}
                        searchFunction={searchData}
                        searchText={searchText}
                        sortFunction={sortData}
                        exportData={exportData}
                        count={totalItems}
                        exportLoader={loader}
                        onReset={resetFilters}
                        isLoadingData={isLoadingData}
                        isLoadingDataScroll={isLoadingDataScroll}
                        isSearch={true}
                        columnsButton={true}
                        resetButton={true}
                        allColumnsStyle=" max-w-[200px] min-w-[200px]"
                        // customButton={customButton}
                        // selectId={selectId}
                    />
                </div>
            ) : (
                <div className="flex w-full items-center justify-center">
                    <Loader />
                </div>
            )}
            {!closedComparison && (
                <MasterFileDataComparison
                    setClosedComparison={setClosedComparison}
                    className={Pos_module['data-comparison']}
                    data={selectedPOSData}
                    dataLoaded={posDataLoaded}
                    userEmail={userEmail}
                />
            )}
        </div>
    );
};
