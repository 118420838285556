import styles from './members.module.scss';
import classNames from 'classnames';
import { Table } from '../table/table';
import { useParams } from 'react-router-dom';
import { getAllRoles } from '../../services/python-services';
import { useEffect, useState } from 'react';
import { getAllUsers, updateRoles } from '../../services/python-services';
import { CustomButtonGroup } from '../../utils/interfaces';
import { Spinner } from 'flowbite-react';
import { Button } from '../button/button';
import { Link } from 'react-router-dom';
import {
    ArrowLeftIcon,
    ArrowRightIcon
} from '@heroicons/react/24/outline';
import Notiflix from 'notiflix';

export interface MembersProps {
    className?: string;
}

type Data = {
    [key: string]: any;
    countries: object[],
    views: object[],
    divisions: object[],
  };
/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const Members = ({ className }: MembersProps) => {
    const { id } = useParams()
    const [unassignedUsers, setUnassignedUsers] = useState([{ dummy: "data", id: "dummy" }])
    const [userSet, setUserSet] = useState([{id:"dummy"}])
    const [idsArrayLeft, setIdsArrayLeft] = useState<number[]>([])
    const [idsArrayRight, setIdsArrayRight] = useState<number[]>([])
    const [IdBasedRoleInformation, setIdBasedRoleInformation] = useState<Data>({
        accounts: [{id: 1}],
        countries: [{id: 1}],
        divisions: [{id: 1}],
        views: [{id: 1}],
      })
    const [checkAllSwitchLeft, setCheckAllSwitchLeft] = useState(false)
    const [checkAllSwitchRight, setCheckAllSwitchRight] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectAllBooleanLeft, setSelectAllBooleanLeft] = useState(false)
    const [selectAllBooleanRight, setSelectAllBooleanRight] = useState(false)


    /* small bug with select all, if no items are selected the select all checkbox must be unchecked */
    const selectAllLeft = () => {
        setSelectAllBooleanLeft(x=>!x)
        let idsTmp:number[] = [...idsArrayLeft];
        let uniqueArray:number[] = []
        if (checkAllSwitchLeft === false) {
            const holder1:number[] = unassignedUsers.map((x: any) => x.id);
            idsTmp.push(...holder1);
            setCheckAllSwitchLeft(true)
        }
        else {
            idsTmp = []
            setCheckAllSwitchLeft(false)
        }
        for (const item of idsTmp) {
            if (!uniqueArray.includes(item)) {
                uniqueArray.push(item);
            }
        }
        setIdsArrayLeft([...uniqueArray]);
    };

    const selectAllRight = () => {
        setSelectAllBooleanRight(x=>!x)
        let idsTmp:number[] = [...idsArrayRight];
        let uniqueArray:number[] = []
        if (checkAllSwitchRight === false) {
            const holder1:number[] = userSet.map((x: any) => x.id);
            idsTmp.push(...holder1);
            setCheckAllSwitchRight(true)
        }
        else {
            idsTmp = []
            setCheckAllSwitchRight(false)
        }
        for (const item of idsTmp) {
            if (!uniqueArray.includes(item)) {
                uniqueArray.push(item);
            }
        }
        setIdsArrayRight([...uniqueArray]);
    };
    
    const selectIdLeft = (id: number) => {
        let idsTmp = [...idsArrayLeft];
        if (idsTmp.includes(id)) {
            idsTmp = idsTmp.filter((i: number) => i !== id);
        } else {
            idsTmp.push(id);
        }
        setIdsArrayLeft([...idsTmp]);
    };

    const selectIdRight = (id: number) => {
        let idsTmp = [...idsArrayRight];
        if (idsTmp.includes(id)) {
            idsTmp = idsTmp.filter((i: number) => i !== id);
        } else {
            idsTmp.push(id);
        }
        setIdsArrayRight([...idsTmp]);
    };

    const customButton: CustomButtonGroup[] = [
        {
            onClick:  async () => {
                setLoading(true)
                let unmodifiedBodyHolder = {...IdBasedRoleInformation}
                /*you need to first transform your unmodified data to the payload and then send it as a body in the patch api  */
                let countriesIdsArray = (unmodifiedBodyHolder["countries"] as object[]).map((x: any) => x["id"]);
                let accountsIdsArray = (unmodifiedBodyHolder["accounts"] as object[]).map((x: any) => x["id"]);
                let viewsIdsArray = (unmodifiedBodyHolder["views"] as object[]).map((x: any) => x["id"]);
                let divisionsIdsArray = (unmodifiedBodyHolder["divisions"] as object[]).map((x: any) => x["id"]);
                let userIdsArray = (unmodifiedBodyHolder["user"] as object[]).map((x: any) => x["id"]);

                let payload = {
                    "role_name": unmodifiedBodyHolder["role_name"],
                    "status": unmodifiedBodyHolder["status"],
                    "countries": countriesIdsArray,
                    "accounts": accountsIdsArray,
                    "views": viewsIdsArray,
                    "divisions": divisionsIdsArray,
                    "users": userIdsArray
                };
                let holderUserSetIdsArray = userSet.map(x => x.id);
                (payload as Data)["users"] = holderUserSetIdsArray;
                await updateRoles(id, payload) 
                await setLoading(false)
                setSelectAllBooleanLeft(false)
                setSelectAllBooleanRight(false)
                Notiflix.Notify.success('Members Saved Successfully');
                 /* here is where you need to call the patch api and pass a parameter of the entire role object */
            },
            children: (
                <div className="flex gap-2">
                    Save
                    {loading ? (<Spinner size="sm" />) : (<></>) }
                </div>
            ),
            styleProp:
                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center',
        },
        {
            onClick: () => {
                
            },
            children: (<Link to={`/roles`}>Cancel</Link>),
            styleProp:
                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center',
        },
    ];

    console.log('userSet', userSet)
    console.log("unassignedusers", unassignedUsers)

    async function initialDataCall() {
        try {
            const IdBasedRoleInformationHolder = await getAllRoles(id);
            const allUsersHolder = await getAllUsers();         
            await setIdBasedRoleInformation(IdBasedRoleInformationHolder)
            const unassignedUsersHolder = allUsersHolder.filter((user:any) => {
                return !IdBasedRoleInformationHolder["user"].some((us:any) => us.id === user.id);
            });
            const assignedUsersHolder = allUsersHolder.filter((user:any) => {
                return IdBasedRoleInformationHolder["user"].some((us:any) => us.id === user.id);
            });
            console.log("assigned users", assignedUsersHolder)
            await setUnassignedUsers(unassignedUsersHolder);
            await setUserSet(assignedUsersHolder)
           
            
        } catch (error) {
            console.error("Error:", error);
        }
    }

    useEffect(() => {
        initialDataCall()
      }, []);
    

    function leftToRight () {
        const leftHolder = [...unassignedUsers]
        const updatedLeftSide = leftHolder.filter((user:any) => {
            return !idsArrayLeft.some((us:any) => us === user.id);
        });
        const objectsToPushOver = unassignedUsers.filter((user:any) => {
            return idsArrayLeft.some((us:any) => us === user.id);
        })
        let userSetHolder = [...userSet]
        userSetHolder = [...userSetHolder, ...objectsToPushOver];
        setUserSet(userSetHolder)
        setUnassignedUsers(updatedLeftSide)
        setIdsArrayLeft([])
        setSelectAllBooleanLeft(false)
    }
    
    function rightToLeft () {
        const rightHolder = [...userSet] 
        const updatedRightSide = rightHolder.filter((user:any) => {
            return !idsArrayRight.some((us:any) => us === user.id);
        });
        const objectsToPushOver = rightHolder.filter((user:any) => {
            return idsArrayRight.some((us:any) => us === user.id);
        })
        let unassignedUsersHolder: any = [...unassignedUsers]
        unassignedUsersHolder = [...unassignedUsersHolder, ...objectsToPushOver];
        setUserSet(updatedRightSide)
        setUnassignedUsers(unassignedUsersHolder)
        setIdsArrayRight([])
        setSelectAllBooleanRight(false)
    }

    console.log("unassignedUsers", unassignedUsers)
    console.log("userSet", userSet)
    return (
        <div className={classNames(className, styles.flex)}>
        <Table
            title="Unassigned Members"
            columns={[
                { name: 'checkbox', prop: 'id', open: false, show: true, id: "id" },
                { name: 'Full Name', prop: 'full_name', open: false, show: true, id:5, style:{width:"45%"} },
                { name: 'E-Mail', prop: 'email', open: false, show: true, id:1, style:{width:"45%"}},
                { name: "ID", prop: "id", open: false, show: true, id:2, style:{width:"10%"} },
            ]}
            data={unassignedUsers}
            fetchData={function (): object[] {
                return [
                    { A: "box", B: "pencil", C: "Admin", D: "44", E: "PA VE CR NI", F: "MX AV DV", G: "8/02/2023", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                    { A: "box", B: "pencil", C: "PA", D: "32", E: "PA VE PR", F: "MX AV DV", G: "8/02/2023", H: "Priscilla Castillo", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                    { A: "box", B: "pencil", C: "NCA", D: "43", E: "GT SL HN", F: "MX AV DV", G: "22/01/2022", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                    { A: "box", B: "pencil", C: "SCA", D: "76", E: "GT SL HN", F: "MX AV DV", G: "22/01/2022", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                ];
            }}
            haveMoreItems={false}
            updateData={function (): void {
                throw new Error('Function not implemented.');
            }}
            count={unassignedUsers.length}
            searchText=""
            columnsButton={true}
            resetButton={false}
            isSearch={false}
            allColumnsStyle='w-full'
            tableWidth='calc(50vw - 24px)'
            selectId={selectIdLeft}
            selectAll={selectAllLeft}
            idsArray={idsArrayLeft}
            selectAllBoolean={selectAllBooleanLeft}
        />

        <div className={styles.arrowFlex}>
            <div className={styles.buttonMargin}>
                <Button
                    click={rightToLeft}
                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"
                >
                    <ArrowLeftIcon className="h-4 w-4" />
                </Button>
                </div>
                <Button
                    click={leftToRight}
                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"
                >
                    <ArrowRightIcon className="h-4 w-4" />
                </Button>
        </div>
        
        <Table
            title="Assigned Members"
            columns={[
                { name: 'checkbox', prop: 'id', open: false, show: true, id: "id" },
                { name: 'Full Name', prop: 'full_name', open: false, show: true, id:5, style:{width:"45%"} },
                { name: 'E-Mail', prop: 'email', open: false, show: true, id:1, style:{width:"45%"}},
                { name: "ID", prop: "id", open: false, show: true, id:2, style:{width:"10%"} },
            ]}
            data={userSet}
            fetchData={function (): object[] {
                return [
                    { A: "box", B: "pencil", C: "Admin", D: "44", E: "PA VE CR NI", F: "MX AV DV", G: "8/02/2023", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                    { A: "box", B: "pencil", C: "PA", D: "32", E: "PA VE PR", F: "MX AV DV", G: "8/02/2023", H: "Priscilla Castillo", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                    { A: "box", B: "pencil", C: "NCA", D: "43", E: "GT SL HN", F: "MX AV DV", G: "22/01/2022", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                    { A: "box", B: "pencil", C: "SCA", D: "76", E: "GT SL HN", F: "MX AV DV", G: "22/01/2022", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                ];
            }}
            haveMoreItems={false}
            updateData={function (): void {
                throw new Error('Function not implemented.');
            }}
            count={userSet.length}
            searchText=""
            columnsButton={true} 
            resetButton={false} 
            isSearch={false}
            allColumnsStyle='w-full'
            tableWidth='calc(50vw - 24px)'
            customButton={customButton}
            selectId={selectIdRight}
            selectAll={selectAllRight}
            idsArray={idsArrayRight}
            selectAllBoolean={selectAllBooleanRight}
            isRolesTable={true}
        />
        
        </div>
        )
};


