import classNames from 'classnames';
import { Table } from '../table/table';
import { useParams } from 'react-router-dom';
import { getAllCountries, getAllRoles, getAllViews, getAllDivisions, updateRoles, postActionLogs } from '../../services/python-services';
import { useContext, useEffect, useState } from 'react';
import { CustomButtonGroup } from '../../utils/interfaces';
import { Spinner } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { RMContext } from '../../context/RMContext';
import { viewInArrayViews } from '../../utils/functions';

export interface EditRoleProps {
    className?: string;
    selector: string;
}

interface Row {
    id: number;
    // Add other properties as needed
  }

/* type Role = {
    // [key in RoleKeys]: object[]
    [key: string]: object[] | Number,
    countries: object[],
    views: object[],
    divisions: object[],
} */

type Data = {
    [key: string]: any;
    countries: object[],
    views: object[],
    divisions: object[],
  };


export const EditRole = ({ className, selector }: EditRoleProps) => {
    const { t, id } = useParams()
    const [loading, setLoading] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [allRows, setAllRows] = useState<Row[]>([{ id: 1 }])
    const [selectAllBoolean, setSelectAllBoolean] = useState(false)
    const [idsArray, setIdsArray] = useState<number[]>([]);
    const [roleData, setRoleData] = useState<Data>({
        accounts: [{id: 1}],
        countries: [{id: 1}],
        divisions: [{id: 1}],
        views: [{id: 1}],
      });
    

    let columns: any
    let rolesRows: any
    let title: string
    if (selector === "countries") {
        title = "Countries"
    }
    else if (selector ==="divisions") {
        title = "Divisions"
    }
    else {
        title = "Views"
    }

    function removeDuplicates(arr:any) {
        return arr.filter((item:any,
            index:any) => arr.indexOf(item) === index);
    }
 

     async function allCountriesList() {
        const resData: any = await getAllCountries()
        await setAllRows(resData)
        let idsArrayHolder1 = resData.map((country: any) => {
            const a = rolesRows[selector]?.filter((rc: any) => rc.id === country.id);
            return (a.length > 0) ? country.id : null;
        });
        let idsArrayHolder2 = [...idsArrayHolder1]
        let idsArrayHolder3 = removeDuplicates(idsArrayHolder2)
        await setIdsArray(idsArrayHolder3)
    } 

    async function allViewsList() {
        const resData: any = await getAllViews()
        await setAllRows(resData)
        let idsArrayHolder1 = resData.map((country: any) => {
            const a = rolesRows[selector]?.filter((rc: any) => rc.id === country.id);
            return (a.length > 0) ? country.id : null;
        });
        let idsArrayHolder2 = [...idsArrayHolder1]
        let idsArrayHolder3 = removeDuplicates(idsArrayHolder2)
        await setIdsArray(idsArrayHolder3)
    }

    async function allDivisionsList() {
        const resData: any = await getAllDivisions()
        await setAllRows(resData)
        let idsArrayHolder1 = resData.map((country: any) => {
            const a = rolesRows[selector]?.filter((rc: any) => rc.id === country.id);
            return (a.length > 0) ? country.id : null;
        });
        let idsArrayHolder2 = [...idsArrayHolder1]
        let idsArrayHolder3 = removeDuplicates(idsArrayHolder2)
        await setIdsArray(idsArrayHolder3)
    }

    async function idRole(id: any) {
        const resData: any = await getAllRoles(id)
        rolesRows = resData
        setRoleData(rolesRows)
    }

    const customButton: CustomButtonGroup[] = [
        {
            onClick:  async () => {
                await setLoading(true)
                let unmodifiedBodyHolder = {...roleData}
                /*you need to first transform your unmodified data to the payload and then send it as a body in the patch api  */
                let countriesIdsArray = (unmodifiedBodyHolder["countries"] as object[]).map((x: any) => x["id"]);
                let accountsIdsArray = (unmodifiedBodyHolder["accounts"] as object[]).map((x: any) => x["id"]);
                let viewsIdsArray = (unmodifiedBodyHolder["views"] as object[]).map((x: any) => x["id"]);
                let divisionsIdsArray = (unmodifiedBodyHolder["divisions"] as object[]).map((x: any) => x["id"]);

                let payload = {
                    "role_name": unmodifiedBodyHolder["role_name"],
                    "status": unmodifiedBodyHolder["status"],
                    "countries": countriesIdsArray,
                    "accounts": accountsIdsArray,
                    "views": viewsIdsArray,
                    "divisions": divisionsIdsArray
                }
                let heldIds = [...idsArray];
                /* here you need to update the particular property on payload using the ids for that particular one */
                /* (unmodifiedBodyHolder as Data)[selector as keyof typeof roleData] = heldIds */
                (payload as Data)[selector as keyof typeof roleData] = heldIds
                await updateRoles(id, payload)
                setLoading(false) 
                setTrigger(x=>!x)
                 /* here is where you need to call the patch api and pass a parameter of the entire role object */
            },
            children: (
                <div className="flex gap-2">
                    Save
                    {loading ? (<Spinner size="sm" />) : (<></>) }
                </div>
            ),
            styleProp:
            'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center',
    },
    {
        onClick: () => {
            
        },
        children: (<Link to={`/roles`}>Cancel</Link>),
        styleProp:
            'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center',
    },
];


    const selectAll = () => {
        let idsTmp:number[] = [...idsArray];
        if (selectAllBoolean === false) {
            const holder1:number[] = allRows.map((x: Row) => x.id);
            idsTmp.push(...holder1);
            setSelectAllBoolean(true)
        }
        else {
            idsTmp = []
            setSelectAllBoolean(false)
        }
        setIdsArray([...idsTmp]);
    };


    const selectId = (id: number) => {
        let idsTmp = [...idsArray];
        if (idsTmp.includes(id)) {
            idsTmp = idsTmp.filter((i: number) => i !== id);
        } else {
            idsTmp.push(id);
        }
        setIdsArray([...idsTmp]);
        if (idsArray.length>0) {
            setSelectAllBoolean(false)
        }
    };

    /* function preSelectCheckBox () {
        let allRowsHolder = [...allRows]
        let intermediate = allRowsHolder.filter((x:any) => 
            x["included"] === "Yes"
        )
        let idsArrayHolder1 = intermediate.map(x => x.id)
        let idsArrayHolder2 = [...idsArray, ...idsArrayHolder1]
        setIdsArray(idsArrayHolder2)
        
    } */

    useEffect(() => {
        const fetchData = async () => {
            await idRole(id);
            if (selector === "countries") {
                await allCountriesList();
            } else if (selector === "views") {
                await allViewsList();
            } else if (selector === "divisions") {
                await allDivisionsList();
            }
            /* await preSelectCheckBox(); */
        };
        fetchData();
    }, [trigger]);

    if (selector === "countries") {
        columns = [
            { name: 'ID', prop: 'id', open: false, show: true, id: 1, style: {minWidth: "50px", maxWidth: "50px"} },
            { name: 'Name', prop: 'name', open: false, show: true, id: 2 },
            { name: 'Code', prop: 'code', open: false, show: true, id: 3 },
            { name: 'checkbox', prop: 'id', open: false, show: true, id: "id" },
        ]
    }
    else if (selector === "views") {
        columns = [
            { name: 'id', prop: 'id', open: false, show: true, id: 1 },
            { name: 'name', prop: 'name', open: false, show: true, id: 2 },
            { name: 'checkbox', prop: 'id', open: false, show: true, id: "id" },
        ]
    }
    else if (selector === "divisions") {
        columns = [
            { name: 'id', prop: 'id', open: false, show: true, id: 1 },
            { name: 'value', prop: 'value', open: false, show: true, id: 2 },
            { name: 'checkbox', prop: 'id', open: false, show: true, id: "id" },
        ]
    }

    console.log(t)


    return (
        <div className={classNames(className)}>
            <Table
                title={title}
                columns={columns}
                data={(allRows) as []}
                fetchData={function (): object[] {
                    return [
                        { A: "box", B: "pencil", C: "Admin", D: "44", E: "PA VE CR NI", F: "MX AV DV", G: "8/02/2023", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                        { A: "box", B: "pencil", C: "PA", D: "32", E: "PA VE PR", F: "MX AV DV", G: "8/02/2023", H: "Priscilla Castillo", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                        { A: "box", B: "pencil", C: "NCA", D: "43", E: "GT SL HN", F: "MX AV DV", G: "22/01/2022", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                        { A: "box", B: "pencil", C: "SCA", D: "76", E: "GT SL HN", F: "MX AV DV", G: "22/01/2022", H: "Sung Wook Jung", I: "Enabled", J: "Visit Menu", K: "Visit Members" },
                    ];
                }}
                haveMoreItems={false}
                updateData={function (): void {
                    throw new Error('Function not implemented.');
                }}
                count={0}
                searchText=""
                columnsButton={true} 
                resetButton={false} 
                isSearch={false}
                allColumnsStyle='w-full'
                selectId={selectId}
                selectAll={selectAll}
                idsArray={idsArray}
                customButton={customButton}
                selectAllBoolean={selectAllBoolean}
            />
        </div>
    )
};
